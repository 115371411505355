<template>
  <KumulatifTargetPasokanForm mode="Ubah" module="Data Kumulatif Target Pasokan"> </KumulatifTargetPasokanForm>
</template>

<script>
import KumulatifTargetPasokanForm from './form';

const KumulatifTargetPasokanUpdate = {
  name: 'KumulatifTargetPasokanUpdate',
  components: { KumulatifTargetPasokanForm },
};

export default KumulatifTargetPasokanUpdate;
</script>
